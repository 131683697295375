.container1 {
  display: grid;
  align-items: center; 
  grid-template-columns: .5fr 1fr 1fr;
  column-gap: 5px;
 }
 
 /* img {
   max-width: 100%;
   max-height:100%;
 }
  */
 .text {
   font-size: 70px;
 }

 .text-glow {
  color: #fff; /* Adjust the text color as needed */
  text-shadow: 0 0 10px rgb(104, 227, 229), 0 0 20px rgb(97, 173, 236), 0 0 30px rgb(71, 180, 224);
}

.price-tag {
  display: block;
  font-size: 16px;
  color: #3c3e40;
  margin-top: 10px;
  font-weight: 500;
  background-color: aliceblue;
}

.announcement-card {
  margin: 20px 0;
}
.announcement-card .card-body {
  padding: 20px;
}
.announcement-card .card-header {
  background-color: #f8f9fa;
  border-bottom: none;
}
.announcement-card .card-title {
  font-size: 1.25rem;
}
.announcement-card .author {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.announcement-card .author img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.announcement-card .comment-box {
  margin-top: 20px;
}


.spinnerDot {
width: 1rem;
height: 1rem;
border: 0.2em solid rgba(0, 0, 0, 0.1);
border-radius: 50%;
border-top-color: #007bff;
animation: spin 1s linear infinite;
margin-right: 0.5rem;
}

.greenDot {
width: 1rem;
height: 1rem;
background-color: green;
border-radius: 50%;
margin-right: 0.5rem;
}

@keyframes spin {
to { transform: rotate(360deg); }
}



.section-title {
position: relative;
padding-bottom: 1.5rem; /* Adjust spacing as needed */
}

.try-now-link {
position: absolute;
right: 0;
bottom: 6.5rem;
font-size: 0.875rem; /* Adjust the size as needed */
text-decoration: none;
color: #007bff; /* Adjust the color as needed */
white-space: nowrap; /* Prevent text from wrapping */
}

.try-now-link i {
font-size: 0.75rem; /* Adjust the icon size as needed */
}

@media (max-width: 768px) {
.section-title {
    position: static;
    text-align: center;
}

.try-now-link {
    position: static;
    display: inline-block;
    margin-top: 1rem; /* Add some space above the link */
    font-size: 0.75rem; /* Adjust the size for smaller screens */
}
}


.icon-boundary {
width: 80px;
height: 80px;
border: 2px solid #007bff; /* Adjust the color as needed */
border-radius: 50%; /* Makes the boundary circular */
display: flex;
align-items: center;
justify-content: center;
margin: 0 auto; /* Center the icon boundary */
}

.tm-icon {
color: #007bff; /* Adjust the icon color as needed */
}

._failed{ border-bottom: solid 4px red !important; }
._failed i{  color:red !important;  }

._success {
    box-shadow: 0 15px 25px #00000019;
    padding: 45px;
    width: 100%;
    text-align: center;
    margin: 40px auto;
    border-bottom: solid 4px #28a745;
}

._success i {
    font-size: 55px;
    color: #28a745;
}

._success h2 {
    margin-bottom: 12px;
    font-size: 40px;
    font-weight: 500;
    line-height: 1.2;
    margin-top: 10px;
}

._success p {
    margin-bottom: 0px;
    font-size: 18px;
    color: #495057;
    font-weight: 500;
}

.color{
  background-color: rgb(97, 182, 232);
}

.page-title {
  position: relative;
  background: none;
}

.page-title::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('https://static.vecteezy.com/system/resources/thumbnails/008/800/859/original/neural-network-ai-technology-cloud-computing-bits-internet-5g-blue-background-information-back-free-video.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 1; /* Adjust transparency */
  z-index: -1; /* Send behind text */
}

.light-background {
  position: relative;
}



.carousel-container {
  max-width: 100%;
  margin: auto;
  position: relative;
  overflow: hidden;
}

.carousel {
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-track {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: calc(100% * 2); /* Adjust as per the number of items */
}

.carousel-slide {
  flex: 0 0 calc(100% / 3); /* Three items per row */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.card img {
  width: 100%;
  height: auto;
}

.card-footer {
  background-color: #f8f9fa; /* Light gray footer */
  padding: 10px;
  text-align: center;
}

.card-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0;
  color: #333;
}

.card-text {
  font-size: 0.9rem;
  color: #555;
  margin: 5px 0 0;
}

/* Add spacing between title and description */
.card-footer h5 {
  margin-bottom: 5px;
}

.carousel-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  z-index: 1000;
}

.carousel-btn.prev {
  left: 10px;
}

.carousel-btn.next {
  right: 10px;
}

.carousel-btn:hover {
  background-color: rgba(0, 0, 0, 0.7);
}


.carousel-container {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.carousel {
  display: flex;
  align-items: stretch; /* Ensure items stretch to match the tallest element */
  overflow: hidden;
}

.carousel-track {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.carousel-slide {
  flex: 1 0 calc(100% / 3); /* Adjust for three slides per view */
  box-sizing: border-box;
  padding: 10px;
}

.card {
  display: flex;
  flex-direction: column; /* Stack content vertically */
  justify-content: space-between; /* Space content evenly */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #fff;
}

.card img {
  max-height: 200px; /* Optional: Limit image height */
  object-fit: cover; /* Ensure images maintain aspect ratio */
  border-radius: 8px 8px 0 0;
}

.card .card-title {
  margin-top: 10px;
  font-size: 1.25rem;
}

.card .card-text {
  flex-grow: 1; /* Push the rest of the content to fill space */
}


/* General Styles */
.header {
  background: #fff;
  width: 100%;
  z-index: 1000;
  padding: 10px 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.logo {
  display: flex;
  align-items: center;
  gap: 10px;
}

.logo img {
  max-height: 40px;
}

.navmenu {
  display: flex;
  align-items: center;
}

.navmenu ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 20px;
}



.navmenu .dropdown ul {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
}

.navmenu .dropdown ul.show {
  display: block;
}

.mobile-nav-toggle {
  display: none;
  font-size: 24px;
  cursor: pointer;
}

/* Mobile Styles */
@media (max-width: 1200px) {
  .navmenu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: #fff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }

  .navmenu.navmenu-mobile {
    display: flex;
  }

  .navmenu ul {
    flex-direction: column;
    width: 100%;
    gap: 10px;
    padding: 20px;
  }

  .navmenu ul li {
    text-align: center;
    padding: 10px;
  }
  .navmenu ul :hover {
    background-color: #f8f9fa;
  }

  .mobile-nav-toggle {
    display: block;
  }
}
/* Add rotation to the dropdown icon when it's open */
.toggle-dropdown.rotate {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}

/* Ensure dropdown menus are initially hidden */
.dropdown ul {
  display: none;
}

/* Display dropdown when it's open */
.dropdown ul.show {
  display: block;
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.image-wrapper {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}

.zoom-image {
  width: 100%;
  height: 400px;
  transition: transform 0.3s ease;
}

.image-wrapper:hover .zoom-image {
  transform: scale(1.1); /* Zoom effect */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  text-align: center;
}

.overlay h4 {
  font-size: 1.5rem;
}

.image-wrapper:hover .overlay {
  opacity: 1; /* Show text on hover */
}


.navmenu .dropdown ul {
  display: none;
}
.navmenu .dropdown.active ul {
  display: block;
}


.user-dropdown-container {
  position: relative; /* Set as reference for dropdown positioning */
  display: inline-block; /* Align with other elements */
}

.user-dropdown-toggle {
  cursor: pointer;
  color: #333; /* Text color */
  text-decoration: none;
  font-weight: 500;
}

.user-dropdown-menu {
  position: absolute; /* Floating dropdown */
  top: 100%; /* Position below the toggle */
  right: 0; /* Align to the right */
  display: none; /* Hidden by default */
  background-color: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Ensure it's above other elements */
  list-style: none;
  padding: 10px 0;
  border-radius: 4px;
  min-width: 150px;
}

.user-dropdown-container .dropdown-open {
  display: block; /* Show the dropdown when active */
}

.user-dropdown-menu li {
  padding: 8px 16px;
}

.user-dropdown-menu li a {
  color: #333;
  text-decoration: none;
  display: block;
}

.user-dropdown-menu li a:hover {
  background-color: #f4f4f4;
}


.user-dropdown-menu {
  display: none;
}

.user-dropdown-menu.dropdown-open {
  display: block;
}

.icon-wrapper {
  width: 60px;
  height: 60px;
  font-size: 32px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background-color: #007bff;
}

.icon-wrapper:hover {
  transform: scale(1.1);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}

.bg-secondary {
  background-color: #f0f0f0;
}
.text-white {
  color: #ffffff;
}

.course-items-area {
  display: flex;
  flex-wrap: wrap;
  
}

.mix {
  display: flex;
  flex-direction: column;
}

.course-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensures equal spacing for inner elements */
  height: 100%; /* Makes the card stretch */

}


.course-info {
  flex: 1; /* Ensures the info section stretches to fill space */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.set{
  background-repeat: no-repeat;
	background-size: contain;
	background-position: top center;
 
}